<template>
    <settingsPagesLayout>
        <div class="p-inside-manage">
            <div class="page_title">Privacy</div>
            <section class="wrap_s_block">
                <div class="title_section">
                    <h2 class="name_section">Privacy</h2>
                </div>
                <div class="body_ privacy">
                    <h4>In development</h4>
                </div>
            </section>
        </div>
    </settingsPagesLayout>
</template>

<script>
import settingsPagesLayout from "@/layouts/settingsPagesLayout";

export default {
    name: 'privacy',
    components: {
        settingsPagesLayout
    },
}
</script>
